import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { BiSolidCircle } from 'react-icons/bi';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import Phone from './assets/iPhone.png';
import Phone2 from './assets/iPhone2.png';
import Robot from './assets/robot.svg';
import AppPreview from './assets/app-preview.png';

const Download = () => {
  return (
    <Box
      data-aos="fade-up"
      bg="#000"
      pt={['1rem', '13rem']}
      pb={'6rem'}
      position={'relative'}
      px={['.5rem', '5rem']}
      mt={['20px', '0']}
    >
      <Flex
        padding={['1rem', '2rem']}
        border="2px solid transparent"
        borderRadius={'24px'}
        style={{ backgroundOrigin: 'border-box' }}
        backgroundClip={'padding-box, border-box'}
        backgroundImage={
          'linear-gradient(#000, #000), linear-gradient(90deg, #6A11CB 0%, #2575FC 100%)'
        }
        position="relative"
        flexDirection={['column', 'row']}
      >
        <Image
          display={['block', 'none']}
          transform={'translateY(-5rem)'}
          scale={1.1}
          src={AppPreview}
        />
        <Box flex={'4'} p={['1rem 1rem 0 1rem', '2rem']}>
          <Box maxW={['', '700px']} mb="2.5rem" textAlign={['center', 'left']}>
            <Heading
              fontWeight={'400'}
              fontFamily={'poster'}
              letterSpacing={'1px'}
              display={'inline'}
              className="gradient-text"
              lineHeight={['60px', '45px', '57px', '70px']}
              fontSize={['4rem', '6rem']}
            >
              EARN REWARDS ON EVERY PURCHASE
            </Heading>
            <Text
              mt=".5rem"
              maxW={['380px', '500px']}
              fontSize={['1rem', '1.15rem']}
              color="#EAECF0"
            >
              Get up to 5% Cashback and amazing Discounts <br /> Redeem points
              for exclusive deals, gift cards, and to participate in exclusive
              tournaments
            </Text>
          </Box>
          <Flex justifyContent={['center', 'left']} gap={[1, 3]}>
            <a
              href="https://play.google.com/store/apps/details?id=com.gameporte.app&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <PrimaryButton
                icon="playstore"
                py="1rem"
                px="1rem"
                width={['170px', '180px']}
                height="50px !important"
                color="#fff !important"
                border="1px solid #fff"
                borderRadius={['0px 12px 0px 12px', '0px 24px 0px 24px']}
                fontSize={['12px', '1rem']}
              >
                <span>
                  Get it on <br /> <strong>Google Play</strong>
                </span>
              </PrimaryButton>
            </a>
            <a
              href="https://apps.apple.com/ng/app/gameporte/id6503079434"
              target="_blank"
              rel="noopener noreferrer"
            >
              <PrimaryButton
                icon="apple"
                py="1rem"
                px="1rem"
                width={['170px', '180px']}
                height="50px !important"
                color="#fff !important"
                border="1px solid #fff"
                borderRadius={['12px 0px 12px 0px', '24px 0px 24px 0px']}
                fontSize={['12px', '1rem']}
              >
                <span>
                  Download on the <br /> <strong>App Store</strong>
                </span>
              </PrimaryButton>
            </a>
          </Flex>
        </Box>
        <Box flex={'2'} position={'relative'} display={['none', 'flex']}>
          <Image
            position={'absolute'}
            transform={'translateY(-3rem) scale(1.3)'}
            src={AppPreview}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default Download;
