import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import One from './assets/one.svg';
import Two from './assets/two.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import User from './assets/user.svg';

const About = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const teamData = [
    {
      name: 'Ajao Jelil A.',
      role: 'Lead Designer',
      img: User,
      text: 'Lorem ipsum dolor sit amet consectetur. Massa mollis auctor augue augue sit amet consectetur. Massa.',
    },
    {
      name: 'Joy Tabade',
      role: 'Lead Operations',
      img: User,
      text: 'Lorem ipsum dolor sit amet consectetur. Massa mollis auctor augue augue sit amet consectetur. Massa.',
    },
    {
      name: 'Hudgens Stone',
      role: 'Marketing Lead',
      img: User,
      text: 'Lorem ipsum dolor sit amet consectetur. Massa mollis auctor augue augue sit amet consectetur. Massa.',
    },
    {
      name: 'Valkyrie Peridot',
      role: 'Lead Engineer',
      img: User,
      text: 'Lorem ipsum dolor sit amet consectetur. Massa mollis auctor augue augue sit amet consectetur. Massa.',
    },
  ];

  return (
    <Box pt={['.4rem', '75px']} pb={['2rem', '6rem']} px={['.7rem', '3rem']}>
      <Box
        p={['.2rem 1rem 1rem 1rem', '3.5rem 3rem']}
        borderRadius={'16px'}
        bg={['#000', '#FFFFFF1F']}
        mt="5rem"
        position={'relative'}
      >
        <Flex flexWrap={'wrap'} gap={['1.5rem', '6rem']}>
          <Box flex={'1 1 300px'}>
            <Heading
              fontWeight={'400'}
              fontFamily={'poster'}
              letterSpacing={'1px'}
              display={'inline'}
              className="gradient-text"
              lineHeight={['60px', '45px', '57px', '70px']}
              fontSize={['4rem', '6rem']}
            >
              ABOUT US
            </Heading>
            <Text mt="1rem" color={'#FFF'}>
              At Gameporte, we provide a seamless platform tailored for gamers
              to transact, earn, and connect. Our digital wallet and e-commerce
              store empower gamers with secure and efficient payment solutions
              for in-game purchases, subscriptions, and more. Through our
              rewards system, users can earn cashback, claim exclusive deals,
              and participate in tournaments.
            </Text>
          </Box>
          <Box
            height={['230px', '280px']}
            flex={'1 1 300px'}
            backgroundImage={One}
            backgroundSize={'cover'}
            backgroundPosition={'center'}
            borderRadius={'24px'}
          />
        </Flex>
        <Flex
          mt={['3rem', '5rem']}
          flexDirection={['column-reverse', 'row']}
          flexWrap={'wrap'}
          gap={['0', '3rem']}
        >
          <Box
            height={['230px', '270px']}
            flex={'1 1 300px'}
            borderRadius={'24px'}
            backgroundImage={Two}
            backgroundSize={'cover'}
            backgroundPosition={'center'}
          />

          <Box
            flex={'1 1 300px'}
            width={'100%'}
            textAlign={'center'}
            height={['max-content', 'auto']}
            p={['0', '1rem']}
          >
            <Text color={'#FFF'}>
              We aim to redefine gaming transactions by integrating Web 2 and
              Web 3 technologies, ensuring a reliable and innovative payment
              infrastructure for the gaming community. Gameporte is more than
              just a platform—it's where gamers, brands, and creators converge
              to build a thriving ecosystem that supports passion and play.
              <Text mt="1rem" mb="1rem" color={'#FFF'}>
                Join us in shaping the future of gaming, where every transaction
                adds value and every connection creates opportunities.
              </Text>
            </Text>
            {/* <Heading
              fontWeight={'400'}
              fontFamily={'poster'}
              letterSpacing={'1px'}
              display={'inline'}
              className="gradient-text"
              lineHeight={['60px', '45px', '57px', '70px']}
              fontSize={['4rem', '6rem']}
            >
              OUR STAFF
            </Heading> */}
            {/* <Box
              width={['100%', '500px']}
              bg="#232323f3"
              p="1.8rem"
              mt=".7rem"
              borderRadius={'12px'}
              boxSizing="border-box"
            >
              <Slider {...settings}>
                {teamData?.map(({ name, role, text, img }) => (
                  <Box key={name}>
                    <Flex
                      gap="1rem"
                      alignItems={'center'}
                      flexDirection={'row'}
                    >
                      <Box
                        height={'80px'}
                        width={'80px !important'}
                        borderRadius={'6px'}
                        backgroundImage={img}
                        backgroundSize={'cover'}
                      />
                      <Box textAlign={'left'}>
                        <Text
                          color="#FFF"
                          fontWeight={'600'}
                          fontSize={'1.1rem'}
                        >
                          {name}
                        </Text>
                        <Text
                          mt=".2rem"
                          fontWeight={'600'}
                          color="#FFF"
                          fontStyle={'italic'}
                          fontSize={'.9rem'}
                          opacity={'.7'}
                        >
                          {role}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </Slider>
            </Box> */}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default About;
