import React from 'react';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import './hero.css';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import Len1 from './assets/lens1.svg';
import Len2 from './assets/lens2.svg';
import times from 'lodash/times';
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import HeroImage from './assets/hero-img.png';
import HeroImageMobile from './assets/hero-img_mobile.png';

const Hero = () => {
  const [counterOn, setCounterOn] = React.useState(false);

  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <Box
        minHeight={['99vh', '100vh']}
        px="1rem"
        pt={['4rem', '8.3rem']}
        bg="#000"
        position={'relative'}
      >
        <Box
          display={['none', 'block']}
          height={'100vh'}
          width="100%"
          position={'absolute'}
          top={'0'}
          bottom={'0'}
          right={'0'}
          left="0"
          // transform={["scale(2)", "scale(2)"]}
        >
          <img
            style={{
              position: 'absolute',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            src={HeroImage}
            alt="hero"
            width="inherit"
            height="inherit"
          />
        </Box>
        <Box
          display={['block', 'none']}
          width="100%"
          // heigh="100vh"
          position={'absolute'}
          top={'5rem'}
          bottom={'0'}
          right={'0'}
          left="0"
          // transform={["scale(2)", "scale(2)"]}
        >
          <img
            style={{
              // position: 'absolute',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            src={HeroImageMobile}
            alt="hero"
            width="inherit"
            height="inherit"
          />
        </Box>

        <Box pt="" textAlign={'center'} mt={['250px', '500px']}>
          <Box pb="3.2rem" zIndex={'2'} position={'relative'}>
            <Box mb="1.9rem">
              <Heading
                fontWeight={'500'}
                fontFamily={'poster'}
                letterSpacing={'3px'}
                display={['block', 'none']}
                className="gradient-text"
                lineHeight={['90px', '45px', '57px', '70px']}
                fontSize={['5rem', '8.1rem']}
              >
                TRANSACT{' '}
                <Text display={['block', 'none']}> {/* <br /> */}</Text>
                <Text display={['none', 'block']}> |</Text> EARN{' '}
                <Text display={['block', 'none']}> {/* <br /> */}</Text>
                <Text display={['none', 'block']}>|</Text>
                CONNECT
              </Heading>
              <Heading
                fontWeight={'500'}
                fontFamily={'poster'}
                letterSpacing={'3px'}
                display={['none', 'block']}
                className="gradient-text"
                // lineHeight={['90px', '45px', '57px', '70px']}
                fontSize={['5rem', '8.1rem']}
              >
                TRANSACT | EARN | CONNECT
              </Heading>
            </Box>
            <Text
              mb="1rem"
              maxW={['350px', '1000px']}
              fontFamily={'Open Sans'}
              lineHeight={['30px', '']}
              mx="auto"
              fontSize={['1rem', '1.8rem']}
              color="#EAECF0"
              fontWeight={'600'}
            >
              Digital wallet and payment infrastructure for gamers
            </Text>
            <Text
              mb="3rem"
              maxW={['350px', '1000px']}
              fontFamily={'Open Sans'}
              lineHeight={['30px', '']}
              mx="auto"
              fontSize={['1rem', '1.5rem']}
              color="#EAECF0"
            >
              Make fast, easy and secure payments for your game vouchers and
              subscriptions with the Gameporte digital wallet and earn rewards
              on every purchase
            </Text>

            <Flex
              maxW={'700px'}
              mx="auto"
              gap={['.5rem', '2rem']}
              justifyContent={'center'}
              alignItems={'center'}
              display={['flex']}
              mt={['.5rem', '0']}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.gameporte.app&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrimaryButton
                  icon="playstore"
                  py="1rem"
                  width="180px"
                  height="50px !important"
                  color="#fff !important"
                  border="1px solid #fff"
                  borderRadius={['0px 12px 0px 12px', '0px 24px 0px 24px']}
                  fontSize={['12px', '1rem']}
                  backgroundColor="#000"
                >
                  <span>
                    Get it on <br /> <strong>Google Play</strong>
                  </span>
                </PrimaryButton>
              </a>
              <a
                href="https://apps.apple.com/ng/app/gameporte/id6503079434"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrimaryButton
                  icon="apple"
                  py="1rem"
                  width="180px"
                  height="50px !important"
                  color="#fff !important"
                  border="1px solid #fff"
                  borderRadius={['12px 0px 12px 0px', '24px 0px 24px 0px']}
                  fontSize={['12px', '1rem']}
                  backgroundColor="#000"
                >
                  <span>
                    Download on the <br /> <strong>App Store</strong>
                  </span>
                </PrimaryButton>
              </a>
            </Flex>
          </Box>
        </Box>
      </Box>
    </ScrollTrigger>
  );
};

export default Hero;
