import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  UnorderedList,
  ListItem,
  Text,
  Link,
} from '@chakra-ui/react';
import Bg from './bg.svg';

const data = [
  {
    question: 'What is Gameporte?',
    answer: (
      <Text>
        Gameporte is a platform that provides a digital wallet and e-commerce
        solutions tailored for gamers. It enables seamless transactions for
        in-game purchases, subscriptions, and more, while offering rewards such
        as cashback, exclusive deals, and opportunities to participate in
        tournaments.
      </Text>
    ),
  },
  {
    question: 'How does the digital wallet work?',
    answer: (
      <Text>
        The Gameporte Wallet allows gamers to securely store funds, make
        payments for games, and purchase items directly from our platform. It
        integrates Web 2 and Web 3 technologies to provide reliable and
        decentralized payment options.
      </Text>
    ),
  },
  {
    question: 'What rewards can I earn on Gameporte?',
    answer: (
      <Text>
        Gamers can earn up to 5% cashback, claim points for exclusive deals and
        gift cards, and access unique rewards such as tournament entries and
        gaming collectibles.
      </Text>
    ),
  },
  {
    question: 'How does Gameporte enhance community engagement?',
    answer: (
      <Text>
        Gameporte provides features like chat channels, AI-assisted matchmaking,
        and community-building tools to help gamers connect and interact
        meaningfully.
      </Text>
    ),
  },
  {
    question: 'Does Gameporte support Web 3 payments?',
    answer: (
      <Text>
        Yes, Gameporte integrates Web 3 payment systems, enabling transactions
        using cryptocurrencies and the purchase of NFTs related to gaming.
      </Text>
    ),
  },
  {
    question: ' What kind of games and items can I purchase on the platform?',
    answer: (
      <Text>
        You can buy game vouchers, in-game currency, skins, and other
        gaming-related items through our e-commerce platform.
      </Text>
    ),
  },
  {
    question: 'How secure is Gameporte?',
    answer: (
      <Text>
        Your personal data and transactions are protected by high-security
        measures on the Gameporte platform. We use KYC (Know Your Customer)
        verification to provide an added level of account security, in addition
        to our wallet being encrypted.
      </Text>
    ),
  },
  {
    question: 'Who can use Gameporte?',
    answer: (
      <Text>
        Gameporte is designed for all types of gamers—from casual players to
        competitive eSports enthusiasts
      </Text>
    ),
  },
  {
    question: 'Is Gameporte available globally?',
    answer: (
      <Text>
        Yes, Gameporte is a global platform, accessible to gamers worldwide with
        local currency options and region-specific rewards.
      </Text>
    ),
  },
  {
    question: 'How can I join Gameporte?',
    answer: (
      <Text>
        Visit our website, create an account, and start using the Gameporte
        Wallet and e-commerce platform. It’s quick, secure, and free to sign up!
      </Text>
    ),
  },
  {
    question: 'Does Gameporte partner with brands or developers?',
    answer: (
      <Text>
        Yes, Gameporte collaborates with game developers, brands, and creators
        to provide exclusive deals, sponsorships, and tournaments that enhance
        the gaming ecosystem.
      </Text>
    ),
  },
  {
    question: 'How do I contact Gameporte for support?',
    answer: (
      <Text>
        You can reach out to our support team on the app or by emailing us at{' '}
        <Link href="mailto:hello@gameporte.com">hello@gameporte.com</Link>.
        We're always glad to help!
      </Text>
    ),
  },
];

const Faq = () => {
  return (
    <Box
      px={['1rem', '3rem', '6rem']}
      bg="#000000"
      pt={['5rem', '7rem']}
      pb="4rem"
      position={'relative'}
    >
      <Box
        flex={'1'}
        maxW={'550px'}
        mt="2rem"
        zIndex={'6 !important'}
        textAlign={'left'}
      >
        <Heading
          fontWeight={'400'}
          fontFamily={'poster'}
          letterSpacing={'1px'}
          display={'inline'}
          className="gradient-text"
          lineHeight={['60px', '45px', '57px', '70px']}
          fontSize={['4rem', '6rem']}
        >
          FREQUENTLY ASKED QUESTIONS
        </Heading>
        <Divider mt="1.5rem" />
        <Accordion
          mt="1.5rem"
          allowToggle
          allowMultiple={false}
          defaultIndex={[0]}
        >
          {data?.map(({ question, answer }, index) => {
            return (
              <AccordionItem
                key={index}
                borderColor={'#E4E7EC'}
                border="none"
                borderBottom=".1px solid #D9DBE9"
              >
                <h2>
                  <AccordionButton padding={'.6rem 0'}>
                    <Box
                      color={'#E4E7EC'}
                      fontWeight="400"
                      fontSize={['.97rem', '1.1rem']}
                      padding={'.6rem 0'}
                      as="span"
                      flex="1"
                      textAlign="left"
                      borderTop={'none'}
                      _expanded={{ fontWeight: '500' }}
                      fontFamily={'Open Sans'}
                    >
                      {question}
                    </Box>
                    <AccordionIcon color={'#E4E7EC'} />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  color={'#D0D5DD'}
                  fontSize={['.85rem', '.95rem']}
                  pb={4}
                  padding={'.7rem 0 1.3rem 0'}
                  maxW="90%"
                  textAlign={'left'}
                >
                  {answer}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
      <Box
        width={'900px'}
        height={'560px'}
        backgroundImage={Bg}
        backgroundPosition={'right'}
        backgroundSize={'contain'}
        backgroundRepeat={'no-repeat'}
        position={'absolute'}
        top="24%"
        right={'0'}
        zIndex={'1 !important'}
        display={['none', 'block']}
      />
    </Box>
  );
};

export default Faq;
